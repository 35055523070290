import React, { Component } from 'react';
import { Row, Col, Form, Icon, Input, Button, Select, Radio, Divider, Checkbox } from 'antd';
import { NavLink, Link } from 'react-router-dom';
import SocialLogin from '../login/socialLogin/SocialLogin';
import { connect } from 'react-redux';
import * as actions from './registrationActions';
import Features from '../../components/Features';
import csc from 'country-state-city';
import { UITextMessages } from '../../helpers/constants/uiTextMessages';
import userTypes from '../../helpers/enums/userTypes';
import ReCAPTCHA from "react-google-recaptcha";

//Image import
import { ReactComponent as EarthGlobe } from '../../assets/images/earth-globe.svg';
import { ReactComponent as OrLOGO } from '../../assets/images/or.svg';
import { ReactComponent as RegisteredSuccess } from '../../assets/images/register-sucessful.svg';
import { ReactComponent as Mail } from '../../assets/images/mail.svg';
import { ReactComponent as User } from '../../assets/images/user.svg';
import { ReactComponent as Lock } from '../../assets/images/pass.svg';
import { ReactComponent as LogoNew } from '../../assets/images/logo_new.svg';
import { ReactComponent as Occupation } from "../../assets/images/occupation.svg";
import { Scrollbars } from 'react-custom-scrollbars';
//Less Import
import './registration.less';
import ReactGA from 'react-ga';
const { Option } = Select;

class Registration extends Component {
  componentDidMount() {
    ReactGA.initialize('UA-1052826-1');
    ReactGA.pageview(window.location.pathname);
  }
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      password: '',
      country: '',
      occupation:'',
      userTypeId: userTypes.EL,
      checked: true,
      disabled: false,
      occupationVisibility: "true",
      submit: false,
      recaptchaValue:""
    };
    this.recaptchaRef = React.createRef(); 
    this.onChange = this.onChange.bind(this);
  }
  toggleChecked = () => {
    this.setState({ checked: !this.state.checked });
  };

  toggleDisable = () => {
    this.setState({ disabled: !this.state.disabled });
  };

  onChange = (e) => {
    this.setState({
      checked: e.target.checked,
      email: e.target.value,
    });
    if (e.target.checked) {
      this.setState({
        submit: false,
      });
    }
  };

  
  onChangerecaptcha = (value) =>{
    console.log(value);
    this.setState({ recaptchaValue: value });
  }
  onCountrySelect = (countryName, countryId) => {
    this.setState({ country: countryName, countryId: countryId });
  };

  handleUserRegistration = (e) => {
    e.preventDefault();
    const state = this.state;

    if (!state.recaptchaValue) {
      // Handle reCAPTCHA not completed error
      alert("reCAPTCHA not completed");
      console.log("reCAPTCHA not completed");
      return;
    }
    if (this.state.checked) {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          console.log("state.email :",state.email);
          this.props.registerUser({ ...values,email: values.email, country: state.country, userTypeId: state.userTypeId ,'g-recaptcha-response': state.recaptchaValue});
        }
      });
      this.setState({
        submit: false,
      });
    } else {
      this.setState({
        submit: true,
      });
       // Reset the reCAPTCHA value after dispatching the login action
       if (this.recaptchaRef.current) {
        this.recaptchaRef.current.reset();
      }
    }
  };
  handleAccountTypeChange = (event) => {
    this.setState({
      userTypeId: event.target.value,
    });
  };
  
  onOccupationSelect = (occupationName, occupationId) => {
    this.setState({
      occupation: occupationName,
      occupationId: occupationId,
      occupationChanged: true,
    });
    
    if (occupationId.toLowerCase() === "other") {
      this.setState({
        occupationVisibility: false,
      });
    }
   
  };

  getoccupationList = () => {
    let occupationList = [{"id":"Teacher","name":"Teacher"} ,{"id":"Instructor","name":"Instructor"},{"id":"Professor","name":"Professor"},{"id":"Lecturer","name":"Lecturer"},
    {"id":"Trainer","name":"Trainer"},{"id":"EducationConsultant","name":"Education Consultant"},{"id":"Author","name":"Author"},{"id":"CourseManager","name":"Course Manager"},
    {"id":"eLearningDesigner","name":"eLearning Designer"},{"id":"CorporateTrainer","name":"Corporate Trainer"},{"id":"InstructionalDesigner","name":"Instructional Designer"},
    {"id":"SubjectMatterExpert","name":"Subject Matter Expert"} ,{"id":"Student","name":"Student"} ,{"id":"Other","name":"Other"} ,
  ]; 
  return occupationList;
  }
  getRegistrationSection = (countries) => {
    const { getFieldDecorator } = this.props.form;
     
  let occupationListwithkey = this.getoccupationList().map((occupation) => {
    return (
      <Option
        onClick={this.onOccupationSelect.bind(this, occupation.name, occupation.id)}
        key={occupation.name}          
      >
        {occupation.name}
      </Option>
      
    );
  });  

    return (
      <div className='registration-container'>
        <Row justify='space-around' style={{ padding: '3% 6.9% 3%' }} align='middle'>
          <Col>
            <Row className='login-logo text-center' align='middle'>
              {/* <img src={Logo} alt='logo' className='login-logo-image' style={{ height: '73px', width: '184px' }} /> */}
              <LogoNew />
            </Row>
            <Row style={{ marginBottom: '0.4%', marginTop: '20px', display:'none' }}>
              <Row className='createAcc_label'>{UITextMessages.label.SELECT_ACCOUNT_TYPE}</Row>
            </Row>
            <Form>
              {/* <Form.Item className='select-option' rules={[{ required: true }]}>
                <Row>
                  <Col xs={1} sm={1} md={1} lg={1} xl={1} className='icon-prefix'>
                    <Icon component={EarthGlobe} />
                  </Col>
                  <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                    <Select defaultValue='Virtual Classroom' onChange={this.handleAccountTypeChange}>
                      <Option value={userTypes.EL}>E-Learning</Option>
                      <Option value={userTypes.VC}>Virtual Classroom</Option>
                    </Select>
                  </Col>
                </Row>
              </Form.Item> */}

              <Form.Item className='select-option' rules={[{ required: true }]} style={{'display':'none'}}>
                <Radio.Group value={this.state.userTypeId} onChange={this.handleAccountTypeChange}>
                  <Radio value={userTypes.VC}>{UITextMessages.label.VIRTUAL_CLASSROOM}</Radio>
                  <Radio value={userTypes.EL}>{UITextMessages.label.RAPTIVITY_2020}</Radio>
                </Radio.Group>
              </Form.Item>
            </Form>
            <Row style={{ marginBottom: '0.4%' }}>
              <Row className='createAcc_label'>{UITextMessages.label.CREATE_AN_ACCOUNT}</Row>
            </Row>
            <Form>
              <Form.Item>
                {getFieldDecorator('name', {
                  rules: [
                    {
                      required: true,
                      validator: (_, value, cb) => {
                        if (value.length > 15) {
                          cb('Username is too long');
                          return;
                        }
                        
                        const pattern = /^(?!.*(http|https|www|\.))[a-zA-Z][a-zA-Z ']{0,14}$/;
                        if (!pattern.test(value)) {
                          cb(UITextMessages.message.ENTER_VALID_NAME);
                          return;
                        }
                        
                        cb();
                      },
                    },
                  ],                  
                })(<Input placeholder={UITextMessages.label.NAME_ASTERISK} className='registration_name' prefix={<Icon component={User} />} />)}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('email', {
                  rules: [
                    // {
                    //   type: 'email',
                    //   message: UITextMessages.message.INVALID_EMAIL_ADDRESS,
                    // },
                    {
                      validator: (_, value, callback) => {
                        console.log('Email validation:', value);

                        if (!value) {
                          // Skip custom validation if the value is empty
                          callback();
                          return;
                        }
                        const allowedPattern = /^[a-zA-Z0-9.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

                        if (value && !allowedPattern.test(value)) {
                           callback(UITextMessages.message.ENTER_EMAIL_ADDRESS);
                            return;
                          }
                        if (value && (value.toLowerCase().endsWith('@yopmail.com') || value.toUpperCase().endsWith('@YOPMAIL.COM'))) {
                          callback(UITextMessages.message.YOPMAIL_NOT_ALLOWED);
                        } else {
                          callback();
                        }
                      },
                    },
                    {
                      required: true,
                      message: UITextMessages.message.ENTER_EMAIL_ADDRESS,
                    },
                  ],
                })(<Input prefix={<Icon component={Mail} />} placeholder={UITextMessages.label.EMAIL_ADDRESS_ASTERISK} autoComplete="off" />)}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('password', {
                  rules: [
                    {
                      // pattern: new RegExp('(?!^[0-9]*$)^([a-zA-Z0-9~!@#$%^&*()_\\[+=,\\]\\{\\}\\\\.?|<>/;:\\\'\\`"-]{6,})$'),
                      pattern: new RegExp(
                        '(?!^[0-9~!@#$%^&*()_\\[+=,\\]\\{\\}\\\\.?|<>/;:\\\'\\`"-]*$)^([a-zA-Z0-9~!@#$%^&*()_\\[+=,\\]\\{\\}\\\\.?|<>/;:\\\'\\`"-]{6,})$'
                      ),
                      message: UITextMessages.message.INVALID_PASSWORD,
                    },
                    {
                      required: true,
                      message: UITextMessages.message.ENTER_PASSWORD,
                    },
                  ],
                })(
                  <Input.Password
                    suffix={
                      // <Tooltip title='Minimum length is 6 alphanumeric characters'>
                      <Icon type='info-circle' style={{ color: 'rgba(0,0,0,.45)' }} />
                      // </Tooltip>
                    }
                    prefix={<Icon component={Lock} />}
                    type='password'
                    placeholder={UITextMessages.label.PASSWORD_ASTERISK}
                  />
                )}
              </Form.Item>
              <Form.Item className='select-option'>
                <Row>
                <Col xs={1} sm={1} md={1} lg={1} xl={1} className='icon-prefix'>
                    <Icon component={EarthGlobe} />
                  </Col>
                  <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                {getFieldDecorator("country", {
                        rules: [
                          {
                            required: true,
                            message: UITextMessages.message.ENTER_COUNTRY,
                          },
                        ],
                        initialValue: 
                          this.state.country &&
                          this.state.country ? this.state.country
                          : undefined
                          
                      })(
                        <Select
                          size="large"
                          showSearch
                          trigger={["click"]}
                          className="dropdown"
                          placeholder={UITextMessages.label.SELECT_COUNTRY}                         
                         
                        >
                          {countries}
                        </Select>
                      )}
                      </Col>
                  {/*<Col xs={1} sm={1} md={1} lg={1} xl={1} className='icon-prefix'>
                    <Icon component={EarthGlobe} />
                  </Col>
                  <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                    <Select showSearch placeholder='Country'>
                      {countries}
                    </Select>
                  </Col> */}
                </Row>
              </Form.Item>
              <Form.Item className='select-option'>
                <Row>
                  <Col xs={1} sm={1} md={1} lg={1} xl={1} className='icon-prefix'>
                    <Icon component={Occupation} />
                  </Col>
                  <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                  {getFieldDecorator("occupation", {
                        rules: [
                          // {
                          //   whitespace:true
                          // },
                          {
                            // pattern: new RegExp('^[^-\\s][a-zA-Z ]*$'),
                            pattern: new RegExp("^[a-zA-Z][a-zA-Z ]+"),
                            message: UITextMessages.message.ENTER_VALID_OCCUPATION,
                          },
                          {
                            required: true,
                            message: UITextMessages.message.ENTER_OCCUPATION,
                          },
                        ],
                        initialValue: 
                          this.state.occupation &&
                          this.state.occupation ? this.state.occupation
                          : undefined
                          
                      })(
                        this.state.occupationVisibility ? (
                          <Select
                            id="occupationSelect"
                            size="large"
                            showSearch
                            trigger={["click"]}
                            className="dropdown"
                            placeholder={UITextMessages.label.OCCUPATION}                            
                          >         
                          {occupationListwithkey}
                           
                          </Select>
                        ) : (
                          <Input
                            id="occupationInput"
                            type="text"
                            size="large"
                            
                            placeholder={UITextMessages.label.OCCUPATION}
                            
                          />
                        )
                      )
                      
                      }
                 
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item className='policy-checker'>
                <Checkbox checked={this.state.checked} disabled={this.state.disabled} onChange={this.onChange}>
                  {UITextMessages.message.GDPR_POLICY}{' '}
                  <a target='_blank' href='https://www.raptivity.com/Raptivity2020-EULA.html'>
                    {' '}
                    {UITextMessages.label.EULA}
                  </a>
                </Checkbox>
                {!this.state.submit ? '' : <span style={{ color: 'red' }}>{UITextMessages.message.PLEASE_ACCEPT_PRIVACY_POLICY}</span>}
              </Form.Item>
              <Form.Item style={{ marginBottom: '0' }}>
              {this.state.checked && !this.state.submit && (
                <ReCAPTCHA 
                  ref={this.recaptchaRef} 
                  sitekey="6LfJPaEpAAAAAGSf8O-x5swfqRDq5eupQRZsCnhA" 
                  onChange={this.onChangerecaptcha}
                  style={{paddingLeft:'28px'}}
                />
              )}
                <Button
                  size='large'
                  type='primary'
                  htmlType='submit'
                  style={{ width: '100%' }}
                  className='register_button'
                  onClick={this.handleUserRegistration}
                >
                  {UITextMessages.label.REGISTER}
                </Button>
              </Form.Item>
            </Form>
            <Form.Item style={{ marginBottom: '5px' }}>
              <Row align='center' style={{ textAlign: 'center' }}>
                <span className='or-txt' style={{ color: '#929292', display: 'block', lineHeight: '1', margin: '4px 0' }}>
                  or
                </span>
                {/* <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                  <Divider dashed />
                </Col>
                <Col xs={2} sm={2} md={2} lg={2} xl={2} align='middle' style={{ marginTop: '10px' }}>
                  <OrLOGO />
                </Col>
                <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                  <Divider dashed />
                </Col> */}
              </Row>
              <Row gutter={16} style={{ marginBottom: '2%' }}>
                <SocialLogin userTypeId={this.state.userTypeId} />
              </Row>
            </Form.Item>
            <Row className='createAcc-link-label'>
              <span>{UITextMessages.label.ALREADY_HAVE_AN_ACCOUNT}&nbsp;</span>
              <NavLink to='/' className='createAcc-Link-Label'>
                {UITextMessages.label.LOG_IN}
              </NavLink>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  getFeatureSectionView = () => {
    return (
      <div className='feature-inner-container'>
        <Features userTypeId={this.state.userTypeId} />
      </div>
    );
  };

  getRegistrationUpdatedSection = () => {
    return (
      <Row align='middle' className='registration-success-container'>
        <Row className='password-image'>
          <RegisteredSuccess />
        </Row>
        <Row className='password-update-label'>{UITextMessages.message.THANKS_FOR_REGISTERING_WITH_US}</Row>
        <Row className='password-update-sub-label'>{UITextMessages.message.PLEASE_CHECK_YOUR_EMAIL_FOR_VERIFICATION}</Row>
        <Row className='back-to-login-label'>
          <span>{UITextMessages.label.BACK_TO} </span>
          <Link
            to='/'
            className='login-link'
            onClick={() => {
              this.props.registrationInfo.status = 0;
            }}
          >
            {UITextMessages.label.LOG_IN}
          </Link>
        </Row>
      </Row>
    );
  };

  render() {
    let countries = [];

    countries = csc.getAllCountries().map((country) => {
      return (
        <Option onClick={this.onCountrySelect.bind(this, country.name, country.id)} key={country.name}>
          {' '}
          {country.name}{' '}
        </Option>
      );
    });

    return (
      <Scrollbars className='scroller-main-wrapper login-scroller register-scroller' style={{ height: 'calc(100vh - 49px)' }}>
        <Row className='wrapper-container'>
          <Row type='flex' justify='center' className='login-main-container'>
            <Col style={{ backgroundColor: 'white' }} lg={12} xl={12} className='register-container'>
              {this.props.registrationInfo.status === 200 ? this.getRegistrationUpdatedSection() : this.getRegistrationSection(countries)}
            </Col>
            <Col lg={12} xl={12} className='feature_container feature_container_background'>
              {this.getFeatureSectionView()}
            </Col>
          </Row>
        </Row>
      </Scrollbars>
    );
  }
}
const mapStateToProps = (state) => {
  let registrationInfo = '';
  if (state.registrationReducer.registrationInfo !== undefined) {
    registrationInfo = state.registrationReducer;
    return registrationInfo;
  } else {
    return { registrationInfo };
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    registerUser: (user) => {
      dispatch(actions.EmployeeRegistration(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'registration' })(Registration));
